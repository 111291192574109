<template>
  <div class="contentConfigTarifRegie">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion des tarifs régie</div>
        <div>
          <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.ConfigurationTarif
            ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
          >

          <b-modal
            id="ConfigurationTarif"
            ref="ConfigurationTarif"
            title="Nouvelle configuration"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal"
            modal-class="cutsom-modal-bootstrap custom-modal-size"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Nouvelle configuration</div>
              <div
                class="iconClose"
                @click.prevent="hideModal('ConfigurationTarif')"
              >
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent="ConfigurationTarifs"
                  class="form-modal-custom-style"
                >
                  <b-form-group
                    label="Régie"
                    label-for="regie"
                    class="vue-select-modal-champ"
                  >
                    <multiselect
                      v-model="configTarif.regie_id"
                      :options="getAllRegie"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="name"
                      track-by="name"
                      :preselect-first="false"
                      required
                      class="select-vue-component-style "
                      :showLabels="false"
                    >
                      <span slot="noResult">Aucune régie trouvé.</span>
                      <span slot="noOptions">Aucune régie trouvé.</span>
                    </multiselect>
                  </b-form-group>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Année"
                      label-for="year"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="year"
                        v-model="configTarif.year"
                        :options="ListYear"
                        required
                        @change="handleChangeYearModel"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Semaine"
                      label-for="semaine"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="semaine"
                        v-model="configTarif.semaine"
                        :options="ListWeekModel"
                        required
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="modal-box-table" v-if="configTarif.type == 'ISO'">
                    <b-table
                      responsive
                      show-empty
                      id="my-table"
                      class="custom-table-style custom-table-config-style"
                      :items="configTarif.salary"
                      :fields="fieldsConfig"
                      sticky-header
                      bordered
                      hover
                      head-variant="light"
                    >
                      <template v-slot:cell(coeff_101_deroule)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_101_deroule"
                          field="coeff_101_deroule"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_101_souffle)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_101_souffle"
                          field="coeff_101_souffle"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_101_rampant)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_101_rampant"
                          field="coeff_101_rampant"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_102_murs)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_102_murs"
                          field="coeff_102_murs"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_102_murs_iti)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_102_murs_iti"
                          field="coeff_102_murs_iti"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_102_murs_ite)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_102_murs_ite"
                          field="coeff_102_murs_ite"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_102_pignons)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_102_pignons"
                          field="coeff_102_pignons"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_103_vide_sanitaire)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_103_vide_sanitaire"
                          field="coeff_103_vide_sanitaire"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                      <template v-slot:cell(coeff_103_plafonds)="data">
                        <Custom-editable-input
                          :value="data.item.coeff_103_plafonds"
                          field="coeff_103_plafonds"
                          :data="data"
                          @handleChangeValueConfig="handleChangeValueConfig"
                        />
                      </template>
                    </b-table>
                  </div>

                  <div class="ModelCol" v-if="configTarif.type == 'ISO'">
                    <b-form-checkbox
                      id="pay_previsite"
                      v-model="configTarif.pay_previsite"
                      button-variant="succes"
                    >
                      Payer previsite
                    </b-form-checkbox>
                  </div>
                  <div class="actionModel">
                    <div class="messageError">
                      <div v-if="getErrorRegieTarifs" class="error">
                        {{ getErrorRegieTarifs }}
                      </div>
                    </div>
                    <b-button type="submit" class="button-valide-style ">
                      <span>
                        Valider
                        <div v-if="getLoagingRegieTarifs" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1"
          />
        </div>

        <div v-if="getLoadingTarifRegie" class="chargement ml-2">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-tarif-regie"
          :items="computedgetregieTarif"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
              @click.prevent.stop="handleUpdate(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine }}
          </template>
          <template v-slot:cell(pourcentage)="data">
            {{ data.item.pourcentage }}
          </template>

          <template v-slot:cell(coeff_101_deroule)="data">
            {{ data.item.coeff_101_deroule }} €
          </template>
          <template v-slot:cell(coeff_101_souffle)="data">
            {{ data.item.coeff_101_souffle }} €
          </template>
          <template v-slot:cell(coeff_101_rampant)="data">
            {{ data.item.coeff_101_rampant }} €
          </template>
          <template v-slot:cell(coeff_102_murs)="data">
            {{ data.item.coeff_102_murs }} €
          </template>
          <template v-slot:cell(coeff_102_murs_iti)="data">
            {{ data.item.coeff_102_murs_iti }} €
          </template>
          <template v-slot:cell(coeff_102_murs_ite)="data">
            {{ data.item.coeff_102_murs_ite }} €
          </template>
          <template v-slot:cell(coeff_102_pignons)="data">
            {{ data.item.coeff_102_pignons }} €
          </template>
          <template v-slot:cell(coeff_103_vide_sanitaire)="data">
            {{ data.item.coeff_103_vide_sanitaire }} €
          </template>
          <template v-slot:cell(coeff_103_plafonds)="data">
            {{ data.item.coeff_103_plafonds }} €
          </template>
          <template v-slot:cell(pay_previsite)="data">
            <b-form-checkbox
              id="pay_previsite_disabled"
              v-model="data.item.pay_previsite"
              button-variant="succes"
              :disabled="true"
            >
            </b-form-checkbox>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowTarifRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-modal
        id="ModelConfigRegie"
        ref="ModelConfigRegie"
        title="Configuration"
        no-close-on-backdrop
        :hide-footer="true"
        :hide-header="true"
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap"
      >
        <div class="hader mb-3">
          <div class="titleSetting">Configuration</div>
          <div class="iconClose" @click.prevent="hideModal('ModelConfigRegie')">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="ConfigurationTarifRegie"
              v-if="RegieToConfig"
              class="form-modal-custom-style"
            >
              <b-form-group
                label="Régie"
                label-for="Régie"
                class="input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="Régie"
                  v-model="RegieToConfig.name"
                  :disabled="true"
                  class="b-form-select-raduis "
                ></b-form-input>
              </b-form-group>
              <template v-if="RegieToConfig.type == 'ISO'">
                <b-form-group
                  label="Précarite"
                  label-for="Précarite"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="Précarite"
                    v-model="RegieToConfig.precarite"
                    :disabled="true"
                    class="b-form-select-raduis "
                  ></b-form-input>
                </b-form-group>
                <div class="ModelCol">
                  <b-form-group
                    label="Type de chauffage"
                    label-for="Type-de-chauffage"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="Type-de-chauffage"
                      v-model="RegieToConfig.type_de_chauffage"
                      :disabled="true"
                      class="b-form-select-raduis "
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Zone climatique"
                    label-for="zone_climatique"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="zone_climatique"
                      v-model="RegieToConfig.zonz_climatique"
                      :disabled="true"
                      class="b-form-select-raduis "
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="101 CP Déroulé"
                    label-for="coeff_101_deroule"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_101_deroule"
                      v-model="RegieToConfig.coeff_101_deroule"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="101 CP SOUFLEE"
                    label-for="coeff_101_souffle"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_101_souffle"
                      v-model="RegieToConfig.coeff_101_souffle"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="101 Rampant"
                    label-for="coeff_101_rampant"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_101_rampant"
                      v-model="RegieToConfig.coeff_101_rampant"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="102 Murs"
                    label-for="coeff_102_murs"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_102_murs"
                      v-model="RegieToConfig.coeff_102_murs"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="ModelCol">
                  <b-form-group
                    label="ITI"
                    label-for="coeff_102_murs_iti"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_102_murs_iti"
                      v-model="RegieToConfig.coeff_102_murs_iti"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="ITE"
                    label-for="coeff_102_murs_ite"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_102_murs_ite"
                      v-model="RegieToConfig.coeff_102_murs_ite"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="102 PIGNONS"
                    label-for="coeff_102_pignons"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_102_pignons"
                      v-model="RegieToConfig.coeff_102_pignons"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="103 vide sanitaire"
                    label-for="coeff_103_vide_sanitaire"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_103_vide_sanitaire"
                      v-model="RegieToConfig.coeff_103_vide_sanitaire"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="103 Plafonds"
                    label-for="coeff_103_plafonds"
                    class="  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="coeff_103_plafonds"
                      v-model="RegieToConfig.coeff_103_plafonds"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-checkbox
                    id="pay_previsite_regie"
                    v-model="RegieToConfig.pay_previsite"
                  >
                    Payer previsite
                  </b-form-checkbox>
                </div></template
              >
              <template v-if="RegieToConfig.type == 'TH'">
                <div class="ModelCol">
                  <b-form-group
                    label="Pourcentage"
                    label-for="Pourcentage"
                    class="  input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="Pourcentage"
                      v-model="RegieToConfig.pourcentage"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </template>

              <div class="actionModel">
                <div class="messageError">
                  <div v-if="getErrorEditRegieTarif" class="error">
                    {{ getErrorEditRegieTarif }}
                  </div>
                </div>

                <b-button class="button-valide-style " type="submit">
                  <span>
                    Valider
                    <div v-if="getlLoadingEditRegieTarif" class="loading ml-2">
                      <div class="spinner-border" role="status"></div></div
                  ></span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      isLoading: false,
      RegieToConfig: null,
      page: 1,
      perPage: 10,
      filterRegie: { name: 'tous', id: null },
      showErrorUser: false,
      filteryear: null,
      filterSemaine: null,
      filterType: 'ISO',
      ListYear: [],
      ListWeek: [],
      ListWeekModel: [],
      fields: [
        { key: 'name', label: 'Nom' },
        {
          key: 'semaine',
          label: 'Semaine'
        },
        { key: 'precarite', label: 'Précarite' },
        { key: 'type_de_chauffage', label: 'Type de chauffage' },
        { key: 'zonz_climatique', label: 'Zone climatique' },
        { key: 'coeff_101_deroule', label: '101 CP Déroulé' },
        { key: 'coeff_101_souffle', label: '101 CP SOUFLEE' },
        { key: 'coeff_101_rampant', label: '101 Rampant' },
        { key: 'coeff_102_murs', label: '102 Murs' },
        { key: 'coeff_102_murs_iti', label: 'ITI' },
        { key: 'coeff_102_murs_ite', label: 'ITE' },
        { key: 'coeff_102_pignons', label: '102 PIGNONS' },
        { key: 'coeff_103_vide_sanitaire', label: '103 vide sanitaire' },
        { key: 'coeff_103_plafonds', label: '103 Plafonds' },
        {
          key: 'pay_previsite',
          label: 'Payer previsite',
          thClass: 'thpay-previsite'
        },
        { key: 'Actions', label: 'Actions' }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      configTarif: {
        regie_id: [],
        year: null,
        type: 'ISO',
        semaine: null,
        salary: [
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          }
        ],
        pay_previsite: false
      },
      fieldsConfig: [
        { key: 'precarite', label: 'Précarité' },
        { key: 'type_de_chauffage', label: 'Type de chauffage' },
        { key: 'zonz_climatique', label: 'Zone climatique' },
        { key: 'coeff_101_deroule', label: '101 CP Déroulé' },
        { key: 'coeff_101_souffle', label: '101 CP SOUFLEE' },
        { key: 'coeff_101_rampant', label: '101 Rampant' },
        { key: 'coeff_102_murs', label: '102 Murs' },
        { key: 'coeff_102_pignons', label: '102 PIGNONS' },
        { key: 'coeff_102_murs_iti', label: 'ITI' },
        { key: 'coeff_102_murs_ite', label: 'ITE' },
        { key: 'coeff_103_vide_sanitaire', label: '103 vide sanitaire' },
        { key: 'coeff_103_plafonds', label: '103 Plafonds' }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchAllRegieForAdmin',
      'fetchAllregieWithCoeff',
      'configeRegieCoeff',
      'makeRegiesTarifs'
    ]),

    resetModal() {
      this.RegieToConfig = null;
      this.configTarif = {
        regie_id: [],
        type: 'ISO',
        year: null,
        semaine: null,
        salary: [
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'classique',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'grande',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'combustible',
            zonz_climatique: 'h3'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h1'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h2'
          },
          {
            coeff_101_souffle: 0,
            coeff_101_deroule: 0,
            coeff_101_rampant: 0,
            coeff_102_murs: 0,
            coeff_102_murs_iti: 0,
            coeff_102_murs_ite: 0,
            coeff_102_pignons: 0,
            coeff_103_vide_sanitaire: 0,
            coeff_103_plafonds: 0,
            precarite: 'simple',
            type_de_chauffage: 'electrique',
            zonz_climatique: 'h3'
          }
        ],
        pay_previsite: false
      };
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleChangeYearModel() {
      var totalWeek = moment({ year: this.configTarif.year }).isoWeeksInYear();
      this.ListWeekModel = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeekModel.push({ value: 's' + week, text: week });
      }
    },
    handleChangeYear() {
      var Filters = JSON.parse(localStorage.getItem('Gestion-Tarif-Regie'));
      Filters.filteryear = this.filteryear;
      localStorage.setItem('Gestion-Tarif-Regie', JSON.stringify(Filters));
      this.page = 1;
      sessionStorage.setItem('page-tarif-regie', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.fetchAllregieWithCoeff({
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType
      });
    },
    handleChangeRegie(payload) {
      this.configTarif = payload.value;
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      localStorage.setItem(
        'Gestion-Tarif-Regie',
        JSON.stringify({
          filterRegie: this.filterRegie,
          filterSemaine: this.filterSemaine,
          filteryear: this.filteryear
        })
      );
      this.page = 1;
      sessionStorage.setItem('page-tarif-regie', this.page);
      this.fetchAllregieWithCoeff({
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        semaine: this.filterSemaine,

        type: this.filterType
      });
    },
    handleUpdate(Regie) {
      this.RegieToConfig = { ...Regie };
      this.$refs['ModelConfigRegie'].show();
    },
    async ConfigurationTarifRegie() {
      const response = await this.configeRegieCoeff({
        regieTarif: this.RegieToConfig
      });
      if (response) {
        this.hideModal('ModelConfigRegie');
      }
    },
    async ConfigurationTarifs() {
      const response = await this.makeRegiesTarifs(this.configTarif);
      if (response) {
        this.hideModal('ConfigurationTarif');
      }
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-tarif-regie', this.page);
      this.fetchAllregieWithCoeff({
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType
      });
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-tarif-regie', this.page);
      this.fetchAllregieWithCoeff({
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType
      });
    },
    handleChangeValueConfig(payload) {
      this.configTarif.salary[payload.index][payload.field] = parseFloat(
        (payload.value + '').replace(',', '.')
      );
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent'),
    Card: () => import('../component/card'),
    CustomEditableInput: () => import('../component/CustomEditableInput.vue')
  },
  mounted() {
    var courantYear = moment().year();
    this.fetchAllRegieForAdmin();
    if (localStorage.getItem('Gestion-Tarif-Regie')) {
      this.filterRegie = JSON.parse(
        localStorage.getItem('Gestion-Tarif-Regie')
      ).filterRegie;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('Gestion-Tarif-Regie')
      ).filterSemaine;
      this.filteryear = JSON.parse(
        localStorage.getItem('Gestion-Tarif-Regie')
      ).filteryear;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'Gestion-Tarif-Regie',
        JSON.stringify({
          filterRegie: this.filterRegie,
          filterSemaine: this.filterSemaine,
          filteryear: this.filteryear
        })
      );
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    if (sessionStorage.getItem('page-tarif-regie')) {
      this.page = sessionStorage.getItem('page-tarif-regie');
    } else {
      sessionStorage.setItem('page-tarif-regie', this.page);
    }
    this.fetchAllregieWithCoeff({
      filterRegie: this.filterRegie,
      page: this.page,
      per_page: this.perPage,
      year: this.filteryear,
      semaine: this.filterSemaine,
      type: this.filterType
    });
  },

  computed: {
    ...mapGetters([
      'getAllRegie',
      'getAllRegieTarif',
      'getTotalRowTarifRegie',
      'getLoadingTarifRegie',
      'getlLoadingEditRegieTarif',
      'getErrorEditRegieTarif',
      'getLoagingRegieTarifs',
      'getErrorRegieTarifs'
    ]),
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    },

    computedgetregieTarif() {
      if (
        this.filterSemaine === null &&
        this.filterRegie !== null &&
        this.filterRegie.id &&
        this.filterRegie.id !== null &&
        this.getAllRegieTarif &&
        this.getAllRegieTarif.length > 0
      ) {
        return this.getAllRegieTarif.filter(
          row => row.name == this.filterRegie.name
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterRegie !== null &&
        this.filterRegie.id &&
        this.filterRegie.id === null &&
        this.getAllRegieTarif &&
        this.getAllRegieTarif.length > 0
      ) {
        return this.getAllRegieTarif.filter(
          row => row.semaine == this.filterSemaine
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterRegie !== null &&
        this.filterRegie.id &&
        this.filterRegie.id !== null &&
        this.getAllRegieTarif &&
        this.getAllRegieTarif.length > 0
      ) {
        return this.getAllRegieTarif.filter(
          row =>
            row.semaine == this.filterSemaine &&
            row.name == this.filterRegie.name
        );
      } else {
        return this.getAllRegieTarif;
      }
    },
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    computedFields() {
      return this.fields;
    }
  }
};
</script>
<style lang="scss" scoped>
.contentConfigTarifRegie {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .body-box-setting {
    height: calc(100vh - 194px) !important;
  }
  .table-tarif-regie {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
  }
}
.label-check-style {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-bottom: 1px;
  margin-right: 8px;
  margin-top: 2px;
}
</style>
<style lang="scss">
.table-tarif-regie {
  td,
  th {
    padding: 5px !important;
  }
}
</style>
